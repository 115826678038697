<template>
  <div class="page publications-interviews">
    <div class="section-header-B">
      <h1>Publications &amp; Interviews</h1>
    </div>
    <div class="md-content">
      <div>
        <ListItem
          v-for="item in publications"
          :key="item.id"
          :title="item.title"
          :details="item.details"
          :link="item.link"
        />
      </div>
    </div>
  </div>
  <div class="page shows-events">
    <div class="section-header-B">
      <h1>Shows &amp; Events</h1>
    </div>

    <div class="md-content">
      <div>
        <ListItem
          v-for="item in events"
          :key="item.id"
          :title="item.title"
          :details="item.details"
          :link="item.link"
        />
      </div>
      <!-- <vue3-markdown-it :source="pageContent" class="markdown" /> -->
    </div>
    <LinkPrompter :links="promptLinks" />
  </div>
</template>

<script>
import ListItem from "@/components/ListItem.vue";
import LinkPrompter from "@/components/LinkPrompter.vue";
import publicationsJSON from "@/page-contents/publications.json";
import eventsJSON from "@/page-contents/events.json";
// import content from "!raw-loader!@/page-contents/publications-and-interviews.md";

import getData from "@/services/dc-service.js";

import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  setup() {
    const siteData = reactive({
      title: `DEAR CLIMATE: Word & Actions`,
      description: `Publications, interviews, shows & events.`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:description`,
          content: `DEAR CLIMATE is a creative-research project working for a better relationship to the more-than-human world, including geo-physical forces, and others species.`,
        },
        {
          property: `og:site_name`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `http://www.dearclimate.net`,
        },
        {
          property: `og:image`,
          content: `http://www.dearclimate.net/dear-climate-og-img.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: `http://www.dearclimate.net/words-and-actions`,
        },
        {
          name: `twitter:title`,
          content: computed(() => siteData.title),
        },
        {
          name: `twitter:description`,
          content: computed(() => siteData.description),
        },
        {
          name: `twitter:image`,
          content: `http://www.dearclimate.net/dear-climate-og-twitter-img.jpg`,
        },
      ],
    });
  },
  name: "WordsActions",
  components: {
    ListItem,
    LinkPrompter,
  },
  data: function () {
    return {
      publications: [],
      events: [],
      // pageContent: content,
      promptLinks: [
        {
          title: "Shows & Events",
          description: "Lectures, panels, and appearances in group shows.",
          target: "/shows-and-events",
        },
        {
          title: "Press",
          description: "Media coverage on DEAR CLIMATE.",
          target: "/press",
        },
      ],
    };
  },
  methods: {
    fetchData() {
      console.log("Getting data from CMS.");
      getData("publications").then((response) =>
        this.processData(response.data)
      );
    },
    processData(input, target) {
      const fetchedData = input.data;
      for (let i = 0; i < fetchedData.length; i++) {
        let thisEntry = {};
        thisEntry.id = fetchedData[i].id;
        thisEntry.title = fetchedData[i].attributes.title;
        thisEntry.details = fetchedData[i].attributes.details;
        if (fetchedData[i].attributes.url) {
          thisEntry.link = fetchedData[i].attributes.url;
        } else {
          thisEntry.link = null;
        }
        target.push(thisEntry);
      }
      console.log(`Data loaded: ${input.data}`);
    },
  },
  mounted() {
    // this.fetchData();
    this.processData(publicationsJSON, this.publications);
    this.processData(eventsJSON, this.events);
  },
};
</script>
